@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

.App {
  min-height: 100vh;
}

body {
  background-color: #fff;
  overflow-x: auto;
  font-family: "Poppins", sans-serif;
}

.page-container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.preview {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}

.bill-container {
  max-width: 21.5625rem;
  margin: auto;
  width: 100%;
}

.bill {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 1.25rem 1.5625rem;
  margin-top: 0.75rem;
  padding-bottom: 0.625rem;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: none;
}

.logo {
  display: flex;
  justify-content: center;
  height: 8rem;
  width: 8rem;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  margin-bottom: 0.9375rem;
  background: #4b4e93;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.storename {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #323a52;
  margin-bottom: 0.625rem;
}

.twogrid {
  display: flex;
  justify-content: space-between;
}

.twogrid > div {
  max-width: 50%;
}

.right {
  text-align: right;
}

.staffid {
  margin-top: 1.875rem;
  margin-bottom: 0.9375rem;
}

.twogrid {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  color: #323a52;
}

.smheader {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
  text-align: center;
  margin-bottom: 0.625rem;
}

.border {
  height: 1px;
  width: 100%;
  margin-bottom: 0.9375rem;
  margin-top: 0.9375rem;
  background-image: linear-gradient(to right, transparent 50%, #474747 50%);
  background-size: 10px 100%;
}

.mt-10 {
  margin-top: 0.625rem;
}

.type {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 400;
}

.items .twogrid {
  margin-bottom: 0.625rem;
  align-items: flex-end;
}

.items .right {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}

.pricing .twogrid {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}

.pricing .twogrid:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.thankyou {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  max-width: 145px;
  margin: auto;
  text-align: center;
}

.site,
.link {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.site {
  margin-bottom: 0.4375rem;
}

.site img,
.link svg {
  margin-right: 0.3125rem;
  height: 1.125rem;
  width: 1.125rem;
}

.jc-sb {
  justify-content: space-between;
}

.flex {
  display: flex;
}
.al-ce {
  align-self: center;
}
.jc-sb {
  justify-content: space-between;
}
.jc-ce {
  justify-content: center;
}
.col {
  flex-direction: column;
}

.custom {
  max-width: 345px;
}

.btn {
  background-color: #fff;
  color: #4b4e93;
  font-size: 18px;
  padding: 1rem 2.5rem;
  border: 1px solid #4b4e93;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  margin: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.qrprint {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
